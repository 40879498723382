import { Camera } from 'lucide-react';
import React, { useState, useRef, useEffect } from 'react';
import { useUser } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';

const MAKE_ENDPOINTS = {
  getProfile: 'https://hook.eu2.make.com/n5cderg3nrlb7caeing8dmb8g6ns5voc',
  uploadPhoto: 'https://hook.eu2.make.com/sperk0b4zmvby3qu21glk7vmw2pqtb41',
  updateProfile: 'https://hook.eu2.make.com/mp9cplkgdrnza1appl5381aatjsc5y3g'
};

export default function ProfilePage() {
  const navigate = useNavigate();
  const { userData } = useUser();
  const [isEditing, setIsEditing] = useState(false);
  const [photo, setPhoto] = useState(null);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false);
  const [error, setError] = useState(null);

  const [profileData, setProfileData] = useState({
    name: userData?.name || '',
    email: userData?.email || '',
    height: '',
    weight: '',
    age: '',
    gender: '',
    activityLevel: 'sedentary',
    dietaryPreferences: '',
    photo: ''
  });

  const [editedData, setEditedData] = useState(profileData);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!userData?.email) return;
      
      try {
        setIsLoading(true);
        setError(null);

        const response = await fetch(`${MAKE_ENDPOINTS.getProfile}?email=${userData.email}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        const responseText = await response.text();
        console.log('Raw response:', responseText);

        if (responseText.trim() === 'Accepted') {
          setProfileData(prev => ({
            ...prev,
            name: userData?.name || '',
            email: userData?.email || '',
          }));
          setEditedData(prev => ({
            ...prev,
            name: userData?.name || '',
            email: userData?.email || '',
          }));
          return;
        }

        try {
          const data = JSON.parse(responseText);
          if (data.success && data.data) {
            setProfileData(data.data);
            setEditedData(data.data);
            if (data.data.photo) {
              setPhoto(data.data.photo);
            }
          }
        } catch (e) {
          console.error('Failed to parse response:', responseText);
          throw new Error('Invalid response format');
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
        setError(error.message);
        setProfileData(prev => ({
          ...prev,
          name: userData?.name || '',
          email: userData?.email || '',
        }));
        setEditedData(prev => ({
          ...prev,
          name: userData?.name || '',
          email: userData?.email || '',
        }));
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfileData();
  }, [userData]);

  const handlePhotoChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      setIsUploadingPhoto(true);
      setError(null);

      const previewUrl = URL.createObjectURL(file);
      setPhoto(previewUrl);

      const base64String = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64 = reader.result.split(',')[1];
          resolve(base64);
        };
        reader.readAsDataURL(file);
      });

      const response = await fetch(MAKE_ENDPOINTS.uploadPhoto, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: userData.email,
          photo: base64String,
          fileName: `profile_${userData.email}_${Date.now()}.${file.name.split('.').pop()}`
        })
      });

      const data = await response.json();

      if (data.success && data.data?.photoUrl) {
        setEditedData(prev => ({ ...prev, photo: data.data.photoUrl }));
      } else {
        throw new Error('Failed to upload photo');
      }
    } catch (error) {
      console.error('Photo upload error:', error);
      setError('Failed to upload photo');
      setPhoto(profileData.photo);
    } finally {
      setIsUploadingPhoto(false);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    setEditedData(profileData);
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      setError(null);

      const response = await fetch(MAKE_ENDPOINTS.updateProfile, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: userData.email,
          data: {
            ...editedData,
            height: editedData.height ? Number(editedData.height) : null,
            weight: editedData.weight ? Number(editedData.weight) : null,
            age: editedData.age ? Number(editedData.age) : null,
          }
        })
      });

      const data = await response.json();

      if (data.success) {
        setProfileData(editedData);
        setIsEditing(false);
      } else {
        throw new Error(data.message || 'Failed to save profile changes');
      }
    } catch (error) {
      console.error('Save error:', error);
      setError('Failed to save profile changes');
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedData(profileData);
    setPhoto(profileData.photo);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      
      <div className="flex-1">
        <div className="flex items-center justify-between px-8 py-6 bg-white border-b">
          <h1 className="text-2xl font-bold">Profile Settings</h1>
          {!isEditing ? (
            <button
              onClick={handleEdit}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
            >
              Edit Profile
            </button>
          ) : (
            <div className="space-x-2">
              <button
                onClick={handleSave}
                disabled={isSaving || isUploadingPhoto}
                className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors disabled:opacity-50"
              >
                {isSaving ? 'Saving...' : 'Save'}
              </button>
              <button
                onClick={handleCancel}
                disabled={isSaving || isUploadingPhoto}
                className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition-colors disabled:opacity-50"
              >
                Cancel
              </button>
            </div>
          )}
        </div>

        <div className="flex-1 space-y-4 p-4 md:p-8 pt-6">
          {error && (
            <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
              {error}
            </div>
          )}

          {isLoading ? (
            <div className="flex justify-center items-center min-h-[60vh]">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
          ) : (
            <div className="grid gap-8 grid-cols-1 md:grid-cols-12">
              {/* Photo Section */}
              <div className="col-span-1 md:col-span-4 bg-white rounded-lg shadow p-6">
                <div className="flex flex-col items-center gap-4">
                  <div className="relative w-40 h-40 rounded-full overflow-hidden bg-gray-100">
                    {isUploadingPhoto ? (
                      <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="animate-spin rounded-full h-8 w-8 border-2 border-white"></div>
                      </div>
                    ) : photo ? (
                      <img
                        src={photo}
                        alt="Profile"
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center">
                        <Camera className="w-12 h-12 text-gray-400" />
                      </div>
                    )}
                    {isEditing && !isUploadingPhoto && (
                      <button
                        onClick={() => fileInputRef.current?.click()}
                        className="absolute bottom-0 w-full py-2 bg-black bg-opacity-50 text-white text-sm hover:bg-opacity-60 transition-colors"
                      >
                        Change Photo
                      </button>
                    )}
                  </div>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handlePhotoChange}
                    disabled={isUploadingPhoto}
                  />
                  <h3 className="text-xl font-semibold">{profileData.name || 'Your Name'}</h3>
                  <p className="text-gray-500">{profileData.email || 'your.email@example.com'}</p>
                  <button
                    onClick={() => navigate('/')}
                    className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                  >
                    Back to Dashboard
                  </button>
                </div>
              </div>

              {/* Profile Details Section */}
              <div className="col-span-1 md:col-span-8 bg-white rounded-lg shadow p-6">
                <div className="grid gap-6 grid-cols-1 md:grid-cols-2">
                  {Object.entries(editedData).map(([key, value]) => (
                    key !== 'photo' && key !== 'email' && (
                      <div key={key} className="flex flex-col">
                        <label className="text-sm font-medium text-gray-700 capitalize mb-1">
                          {key.replace(/([A-Z])/g, ' $1').trim()}
                        </label>
                        {isEditing ? (
                          key === 'activityLevel' ? (
                            <select
                              value={value}
                              onChange={(e) => setEditedData(prev => ({ ...prev, [key]: e.target.value }))}
                              className="p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500 bg-white"
                            >
                              <option value="">Select Activity Level</option>
                              <option value="sedentary">Sedentary</option>
                              <option value="light">Light Activity</option>
                              <option value="moderate">Moderate Activity</option>
                              <option value="active">Active</option>
                              <option value="very_active">Very Active</option>
                            </select>
                          ) : key === 'gender' ? (
                            <select
                              value={value}
                              onChange={(e) => setEditedData(prev => ({ ...prev, [key]: e.target.value }))}
                              className="p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500 bg-white"
                            >
                              <option value="">Select Gender</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="other">Other</option>
                            </select>
                          ) : (
                            <input
                              type={key === 'age' || key === 'height' || key === 'weight' ? 'number' : 'text'}
                              value={value}
                              onChange={(e) => setEditedData(prev => ({ ...prev, [key]: e.target.value }))}
                              className="p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                              min={key === 'age' ? "0" : undefined}
                              step={key === 'height' || key === 'weight' ? "0.1" : undefined}
                            />
                          )
                        ) : (
                          <p className="p-2 bg-gray-50 rounded-md">
                            {value || 'Not set'}
                          </p>
                        )}
                      </div>
                    )
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}