import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import InsightsPage from '../pages/InsightsPage1';
const Header = () => {
  const navigate = useNavigate();
  const { userData, logout } = useUser();
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    console.log('User data in Header:', userData);
  }, [userData]);

  const handleLogout = () => {
    console.log('Logging out...');
    logout();
    navigate('/auth');
  };

  return (
    <div className="bg-white shadow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <div className="flex items-center space-x-8">
            <Link to="/" className="text-2xl font-bold text-blue-600" style={{ direction: 'rtl' }}>
              מצלמזון
            </Link>
            <nav className="flex space-x-4">
              <Link 
                to="/" 
                className="text-gray-600 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium"
              >
                Dashboard
              </Link>
              <Link 
                to="/TakePhotoPage" 
                className="text-gray-600 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium"
              >
                Take Photo
              </Link>
              <Link 
                to="/meals" 
                className="text-gray-600 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium"
              >
                Meal History
              </Link>
              <Link 
                to="/ProfilePage" 
                className="text-gray-600 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium"
              >
                Profile
              </Link>
              <Link to="/insights" className="text-gray-600 hover:text-gray-800">
  Insights
</Link>
            </nav>
            <Link 
                to="/calendar" 
                className="text-gray-600 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium"
              >
                Calendar
              </Link>
            
          </div>
          <div className="flex items-center gap-4">
            <span className="text-gray-600 text-sm">
              {time.toLocaleTimeString()}
            </span>
            <span className="text-gray-600">
              {userData?.name || userData?.email || 'User'}
            </span>
            <button
              onClick={handleLogout}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
