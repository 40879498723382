import React, { useState, useEffect } from 'react';
import { useUser } from '../contexts/UserContext';
import Header from '../components/Header';
import { fetchFromAirtable } from '../utils/api';

const InsightsPage = () => {
  const { userEmail } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [meals, setMeals] = useState([]);
  const [insights, setInsights] = useState([]);

  useEffect(() => {
    const fetchUserMeals = async () => {
      try {
        const data = await fetchFromAirtable('meals', {
          filterByFormula: `{userEmail} = '${userEmail}'`,
        });

        const fetchedMeals = data.records.map(record => ({
          id: record.id,
          date: record.fields.Date,
          foodName: record.fields.foodName || 'Unnamed Meal',
          calories: parseFloat(record.fields.calories) || 0,
          protein: parseFloat(record.fields.protein) || 0,
          carbs: parseFloat(record.fields.carbs) || 0,
          fat: parseFloat(record.fields.fat) || 0,
          ingredients: record.fields.Ingredients || 'No ingredients info',
        }));

        setMeals(fetchedMeals);
        console.log('Fetched meals:', fetchedMeals);
      } catch (err) {
        console.error('Error fetching meals:', err);
        setError('Failed to load meal history.');
      }
    };

    if (userEmail) {
      fetchUserMeals();
    }
  }, [userEmail]);

  const sendToWebhook = async (timeframe) => {
    try {
      setLoading(true);
      let mealsToAnalyze = meals;

      const now = new Date();
      if (timeframe === 'daily') {
        const today = now.toISOString().split('T')[0];
        mealsToAnalyze = meals.filter(m => m.date === today);
      } else if (timeframe === 'weekly') {
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(now.getDate() - 7);
        mealsToAnalyze = meals.filter(m => new Date(m.date) >= sevenDaysAgo);
      } else if (timeframe === 'monthly') {
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(now.getDate() - 30);
        mealsToAnalyze = meals.filter(m => new Date(m.date) >= thirtyDaysAgo);
      }

      console.log('Meals to analyze:', mealsToAnalyze);

      // Convert meals array to a comma-separated string
      const mealsString = mealsToAnalyze.map(meal => 
        `Date: ${meal.date}, Food: ${meal.foodName}, Calories: ${meal.calories}, Protein: ${meal.protein}g, Carbs: ${meal.carbs}g, Fat: ${meal.fat}g, Ingredients: ${meal.ingredients}`
      ).join(' | ');

      const response = await fetch('https://hook.eu2.make.com/l47uj81xqdfttb14yxjx6ina9ki85efe', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userEmail, meals: mealsString, timeframe }),
      });

      if (!response.ok) {
        throw new Error('Failed to send data to webhook.');
      }

      // Handle non-JSON response
      const jsonResponse = await response.json();
      if (jsonResponse.status !== 'Accepted') {
        throw new Error('Unexpected response from webhook.');
      }

      fetchInsights();
    } catch (err) {
      console.error('Error sending data to webhook:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchInsights = async () => {
    try {
      setLoading(true);
      const data = await fetchFromAirtable('Insights', {
        filterByFormula: `{Email} = '${userEmail}'`,
        sort: [{ field: 'Date', direction: 'desc' }],
      });

      const fetchedInsights = data.records.map(record => ({
        id: record.id,
        date: record.fields.Date,
        insight: record.fields.Insight,
      }));

      setInsights(fetchedInsights);
    } catch (err) {
      console.error('Error fetching insights:', err);
      setError('Failed to load insights.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userEmail) {
      fetchInsights();
    }
  }, [userEmail]);

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-3xl font-bold mb-4 text-center">Insights GPT</h1>
        
        <div className="mb-6 flex justify-center space-x-4">
          <button
            onClick={() => sendToWebhook('daily')}
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow"
          >
            Daily Insight
          </button>
          <button
            onClick={() => sendToWebhook('weekly')}
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg shadow"
          >
            Weekly Insight
          </button>
          <button
            onClick={() => sendToWebhook('monthly')}
            className="bg-purple-500 hover:bg-purple-600 text-white font-semibold py-2 px-4 rounded-lg shadow"
          >
            Monthly Insight
          </button>
        </div>

        {loading && (
          <div className="min-h-screen flex items-center justify-center">
            <p>Loading...</p>
          </div>
        )}
        {error && (
          <div className="min-h-screen flex items-center justify-center text-red-500">
            {error}
          </div>
        )}
        {!loading && !error && (
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-2xl font-semibold mb-4">Previous Insights</h2>
            {insights.length === 0 ? (
              <p className="text-center text-gray-500">No insights available.</p>
            ) : (
              <ul>
                {insights.map(insight => (
                  <li key={insight.id} className="mb-4">
                    <p className="text-sm text-gray-600">{new Date(insight.date).toLocaleString()}</p>
                    <p className="text-lg text-gray-800">{insight.insight}</p>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default InsightsPage;