import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import DashboardPage from "./DashboardPage";

export default function TakePhotoPage() {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("unknown@example.com");
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [userInput, setUserInput] = useState('');

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (!userData) {
      console.error("No user data found. Redirecting to login.");
      navigate("/auth");
      return;
    }
    try {
      const { email } = JSON.parse(userData);
      setUserEmail(email);
    } catch (error) {
      console.error("Error parsing userData:", error);
      navigate("/auth");
    }
  }, [navigate]);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "environment" },
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      setErrorMessage("Unable to access the camera.");
      console.error("Camera error:", error);
    }
  };

  const capturePhoto = () => {
    try {
      if (canvasRef.current && videoRef.current) {
        const context = canvasRef.current.getContext("2d");
        canvasRef.current.width = videoRef.current.videoWidth;
        canvasRef.current.height = videoRef.current.videoHeight;

        if (canvasRef.current.width === 0 || canvasRef.current.height === 0) {
          throw new Error("Unable to capture photo. Camera might not be ready.");
        }

        context.drawImage(
          videoRef.current,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
        const dataUrl = canvasRef.current.toDataURL("image/jpeg");
        setImagePreview(dataUrl);
        setSelectedFileName("captured-photo.jpg");
      }
    } catch (error) {
      setErrorMessage("Error capturing photo. Please try again.");
      console.error("Error in capturePhoto:", error);
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setImagePreview(fileReader.result);
        setSelectedFileName(file.name);
      };
      fileReader.readAsDataURL(file);
    }
  };

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleUpload = async () => {
    try {
      if (!imagePreview) {
        throw new Error("No image to upload.");
      }

      setIsUploading(true);
      setErrorMessage("");

      const base64EncodedImage = imagePreview.split(",")[1];
      const payload = {
        data: base64EncodedImage,
        filename: selectedFileName,
        timestamp: new Date().toISOString(),
        email: userEmail,
        userInput: userInput,
      };

      const response = await fetch(
        "https://hook.eu2.make.com/9jxpt6jziqcyd5ghqj8i9s2dav0flqe4",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error(`Upload failed: ${response.status}`);
      }

      setIsSuccess(true);

      // Start countdown
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setIsSuccess(false); // Reset success state
            if (userEmail) {
              navigate('/'); // Navigate to dashboard if user is authenticated
            } else {
              navigate('/auth'); // Navigate to auth page if user is not authenticated
            }
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (error) {
      setErrorMessage(`Upload error: ${error.message}`);
      console.error("Upload error:", error);
    } finally {
      setIsUploading(false);
    }
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow p-6">
          <h1 className="text-2xl font-bold text-gray-900 mb-6 text-center">
            Take or Upload a Photo
          </h1>
          <p className="text-gray-600 text-center mb-6">User Email: {userEmail}</p>

          {/* Add the text input field */}
          <div className="mb-6">
            <label htmlFor="userInput" className="block text-sm font-medium text-gray-700">
              Additional Input
            </label>
            <input
              type="text"
              id="userInput"
              value={userInput}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          {imagePreview ? (
            <div className="flex flex-col items-center">
              <img
                src={imagePreview}
                alt="Preview"
                className="w-full max-w-md rounded-lg mb-4"
              />
              <p className="text-gray-600 mb-4">Filename: {selectedFileName}</p>
              <div className="flex gap-4 flex-wrap justify-center">
                <button
                  onClick={handleUpload}
                  disabled={isUploading}
                  className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
                >
                  {isUploading ? "Uploading..." : "Upload Photo"}
                </button>
                <button
                  onClick={() => setImagePreview(null)}
                  className="px-6 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600"
                >
                  Retake/Upload New Photo
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center">
              <video
                ref={videoRef}
                className="w-full max-w-md rounded-lg mb-4 bg-black"
                autoPlay
                playsInline
              />
              <canvas ref={canvasRef} className="hidden" />
              <div className="flex gap-4 flex-wrap justify-center mb-6">
                <button
                  onClick={startCamera}
                  className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  Start Camera
                </button>
                <button
                  onClick={capturePhoto}
                  className="px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
                >
                  Capture Photo
                </button>
              </div>
              <div className="w-full max-w-md text-center">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileInputChange}
                  className="w-full mb-2"
                />
                <p className="text-gray-500 text-sm">
                  Or upload an image from your device
                </p>
              </div>
            </div>
          )}

          {isSuccess && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-8 rounded-lg shadow-xl max-w-sm w-full mx-4 text-center">
                <div className="text-green-600 text-xl font-bold mb-4">
                  Upload Successful!
                </div>
                <div className="text-gray-600">
                  Your meal will be logged in {countdown} seconds
                </div>
                <div className="mt-4 h-2 bg-gray-200 rounded-full">
                  <div
                    className="h-full bg-green-500 rounded-full transition-all duration-1000"
                    style={{ width: `${(countdown / 10) * 100}%` }}
                  ></div>
                </div>
              </div>
            </div>
          )}

          {errorMessage && (
            <div className="mt-4 p-4 bg-red-100 text-red-700 rounded-lg text-center">
              {errorMessage}
            </div>
          )}

          <div className="flex justify-center mt-6">
            <button
              onClick={() => navigate("/")}
              className="px-6 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 w-48"
            >
              Back to Dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}