import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import DashboardPage from './pages/DashboardPage';
import AuthPage from './pages/AuthPage';
import TakePhotoPage from './pages/TakePhotoPage';
import MealHistoryPage from './pages/MealHistoryPage';
import CalendarView from './pages/CalendarView';
import { UserProvider } from './contexts/UserContext'; // Import UserProvider
import ProfilePage from './pages/ProfilePage'
import InsightsPage from './pages/InsightsPage'; // Import the new page

const App = () => {
  return (
    <UserProvider> {/* Wrap the entire app */}
      <Router>
        <Routes>
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/" element={<DashboardPage />} />
          <Route path="/TakePhotoPage" element={<TakePhotoPage />} />
          <Route path="/meals" element={<MealHistoryPage />} />
          <Route path="/calendar" element={<CalendarView />} />
          <Route path="/ProfilePage" element={<ProfilePage />} />
          <Route path="/insights" element={<InsightsPage />} /> {/* New Insights route */}
          <Route path="*" element={<Navigate to="/auth" replace />} />
        </Routes>
      </Router>
    </UserProvider>
  );
};

export default App;
