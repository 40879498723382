import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';

import { fetchFromAirtable } from '../utils/api';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Header from '../components/Header';

const localizer = momentLocalizer(moment);

export default function CalendarView() {
  const { userEmail } = useUser();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [allEvents, setAllEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('all');

  // State for Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMeal, setSelectedMeal] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const data = await fetchFromAirtable('meals', {
          filterByFormula: `{userEmail} = '${userEmail}'`,
        });

        const meals = data.records.map(record => ({
          id: record.id,
          title: record.fields.foodName|| 'Unknown Food',
          start: new Date(record.fields.Date),
          end: new Date(record.fields.Date),
          calories: parseFloat(record.fields.calories) || 0,
          protein: parseFloat(record.fields.protein) || 0,
          carbs: parseFloat(record.fields.carbs) || 0,
          fat: parseFloat(record.fields.fat) || 0,
          photoUrl: record.fields.photo?.[0]?.url || '',
          description: record.fields.ShortDescription || 'No description available.',
        }));

        setAllEvents(meals);
        setFilteredEvents(meals);
      } catch (err) {
        console.error('Error fetching events:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [userEmail]);

  const handleMonthChange = (e) => {
    const monthValue = e.target.value;
    setSelectedMonth(monthValue);

    if (monthValue === 'all') {
      setFilteredEvents(allEvents);
    } else {
      const filtered = allEvents.filter(event => {
        const eventMonth = moment(event.start).format('YYYY-MM');
        return eventMonth === monthValue;
      });
      setFilteredEvents(filtered);
    }
  };

  const handleSelectEvent = (event) => {
    setSelectedMeal(event);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMeal(null);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <Header />
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Calendar View</h1>
        <div className="mb-4">
          <label htmlFor="month-select" className="block text-sm font-medium text-gray-700">
            Select Month
          </label>
          <select
            id="month-select"
            value={selectedMonth}
            onChange={handleMonthChange}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            <option value="all">All Months</option>
            {Array.from({ length: 12 }, (_, i) => {
              const monthMoment = moment().month(i);
              const monthValue = monthMoment.format('YYYY-MM');
              return (
                <option key={monthValue} value={monthValue}>
                  {monthMoment.format('MMMM YYYY')}
                </option>
              );
            })}
          </select>
        </div>
        <Calendar
          localizer={localizer}
          events={filteredEvents}
          startAccessor="start"
          endAccessor="end"
          titleAccessor="title"
          style={{ height: 500 }}
          onSelectEvent={handleSelectEvent} // Added event handler
        />

        {/* Modal for Meal Details */}
        {isModalOpen && selectedMeal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3">
              <div className="flex justify-between items-center p-4 border-b">
                <h2 className="text-xl font-semibold">Meal Details</h2>
                <button onClick={closeModal} className="text-gray-500 hover:text-gray-700">
                  &#10005;
                </button>
              </div>
              <div className="p-4">
                {selectedMeal.photoUrl && (
                  <img
                    src={selectedMeal.photoUrl}
                    alt={selectedMeal.title}
                    className="w-full h-48 object-cover rounded-md mb-4"
                  />
                )}
                <h3 className="text-lg font-medium mb-2">{selectedMeal.title}</h3>
                <p className="text-sm text-gray-600 mb-4">{selectedMeal.description}</p>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <span className="font-semibold text-blue-600">Calories:</span> {selectedMeal.calories} cal
                  </div>
                  <div>
                    <span className="font-semibold text-green-600">Protein:</span> {selectedMeal.protein}g
                  </div>
                  <div>
                    <span className="font-semibold text-yellow-600">Carbs:</span> {selectedMeal.carbs}g
                  </div>
                  <div>
                    <span className="font-semibold text-red-600">Fat:</span> {selectedMeal.fat}g
                  </div>
                </div>
              </div>
              <div className="flex justify-end p-4 border-t">
                <button
                  onClick={closeModal}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}